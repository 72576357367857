<template>
  <div class="named-textarea">
    <div class="named-textarea__name">
      <label>
        <slot name="label" />
        <span v-if="required" class="required-marker"> * </span>
      </label>
      <label class="hint">
        {{ hint }}
      </label>
    </div>
    <DefaultTextarea :value="value" :rows="rows" @input="(val) => $emit('input', val)" @blur="$emit('blur')" />
    <slot name="error" />
  </div>
</template>

<script>
import DefaultTextarea from '@/components/ui/form/textarea/default'

export default {
  components: {
    DefaultTextarea,
  },
  model: {
    event: 'input',
  },
  props: {
    value: {
      required: false,
      type: String,
      default: null,
    },
    rows: {
      required: false,
      type: Number,
      default: null,
    },
    required: {
      required: false,
      type: Boolean,
      default: false,
    },
    hint: {
      required: false,
      type: String,
      default: null,
    },
  },
}
</script>

<style lang="scss">
@import '@/css/import/vars.scss';

.named-textarea {
  &__name {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin: 0 2px;

    label {
      color: $color-text-light;
      margin-bottom: 3px;
      font-size: 16px;

      .required-marker {
        color: $color-red;
      }
    }

    .hint {
      color: $color-text-very-light;
      font-size: 14px;
    }
  }
}
</style>
