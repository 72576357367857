import SpunqH from './spunq-h'
import SpunqImage from './spunq-image'
import SpunqFile from './spunq-file'
import SpunqText from './spunq-text'

const components = {
  SpunqH,
  SpunqImage,
  SpunqFile,
  SpunqText,
}

function tagRequiresRegistry(tagName) {
  return ['spunq-image', 'spunq-text', 'spunq-file'].includes(tagName.toLowerCase())
}

const entities = {
  amp: '&',
  lt: '<',
  gt: '>',
  nbsp: ' ', // FIXME: Non-breaking space shouldn't be replaced
  quot: '"',
}

function decodeHTMLEntities(text) {
  return text.replace(/&([^;]+);/gm, function (match, entity) {
    return entities[entity] || match
  })
}

function searchTagName(tagName, parent) {
  const stack = [parent]
  while (stack.length) {
    const node = stack.pop()
    if (node.tagName && node.tagName.toLowerCase() === tagName.toLowerCase()) {
      return node
    }
    stack.push(...node.childNodes)
  }
  return stack.pop() || null
}

const hTags = ['spunq-h1', 'spunq-h2', 'spunq-h3', 'spunq-h4', 'spunq-h5', 'spunq-h6']

function makeHeadlineNode(node, childNodes, headlineOffset) {
  const level = hTags.indexOf(node.tagName.toLowerCase()) + 1
  return [
    'spunq-h',
    {
      class: node.classNames,
      attrs: node.attributes,
      props: {
        level,
        offset: headlineOffset,
        // offset: 5,
      },
    },
    childNodes,
  ]
}

/**
 * Recursively traverse and render the tree
 * @param {HTMLElement, TextNode} node - HTMLElement or TextNode
 * @param {Function} h - Vue createElement render function
 * @param {*} _v - Vue createTextVNode function
 */
function renderNode(node, h, config = {}) {
  const { registry, headlineOffset } = config
  /**
   * Recursively render childnodes of type 1 (Element) and 3 (Text)
   */
  const childNodes = node.childNodes
    .filter((child) => child.nodeType === 1 || child.nodeType === 3)
    .map((child) => renderNode(child, h, config))

  /**
   * Elements (1)
   */
  if (node.nodeType === 1) {
    // Root node doesn't have a tagname, so we create a div here
    let tagName = node.tagName === null ? 'div' : node.tagName.toLowerCase()
    const classNames = node.classNames
    const attrs = node.attributes
    const props = tagRequiresRegistry(tagName) ? { registry } : {}
    /**
     * FIXME: spunq-images must not be in <p> tags (prevent in CMS)
     */
    if (node.tagName === 'p') {
      if (searchTagName('spunq-image', node)) {
        tagName = 'div'
      }
    } else if (hTags.includes(tagName)) {
      return h(...makeHeadlineNode(node, childNodes, headlineOffset))
    }
    return h(
      tagName,
      {
        class: classNames,
        attrs,
        props: {
          ...props,
        },
      },
      childNodes
    )
  } else if (node.nodeType === 3) {
    /**
     * Text (3) Vue automatically escapes contents of text nodes, so we need to
     * decode previously generated HTML Entities
     */
    return decodeHTMLEntities(node.rawText)
  }
}

export default function dynamicComponent() {
  return {
    render(h) {
      if (!this.text) {
        return false
      }
      const tree = JSON.parse(this.text)
      return renderNode(tree, h, {
        registry: this.registry,
        headlineOffset: this.headlineOffset,
      })
    },
    props: {
      text: {
        type: String,
        default: '',
      },
      headlineOffset: {
        type: Number,
        default: 1,
      },
      registry: {
        type: Object,
        default() {
          return {}
        },
      },
    },
    components,
  }
}
