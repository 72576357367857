<template>
  <p class="leadtext">
    <slot />
  </p>
</template>

<script>
export default {}
</script>

<style lang="scss">
@import '../../../css/import/vars';

.leadtext {
  margin-bottom: 2rem;
  color: $color-text;
  font-family: $ff-headlines;
  font-weight: 500;
}
</style>
