<template>
  <a v-if="file" :href="url" :title="title" target="_blank" class="file-download">
    <i>
      <Component :is="icon" />
    </i>
    <slot>
      {{ title }}
    </slot>
  </a>
</template>

<script>
import IconFile from '@/components/ui/icons/icon-file.svg'
import IconPdf from '@/components/ui/icons/icon-pdf.svg'

export default {
  components: {
    IconFile,
    IconPdf,
  },
  props: {
    file: {
      type: Object,
      required: true,
    },
  },
  computed: {
    fileId() {
      return this.file.attributes.fileId
    },
    fileName() {
      return this.file.attributes.fileName
    },
    mimeType() {
      return this.file.attributes.mimeType
    },
    title() {
      return this.file.attributes.title
    },
    icon() {
      if (this.mimeType.includes('pdf')) {
        return IconPdf
      }
      return IconFile
    },
    baseUrl() {
      const fileServiceBaseUrls = this.$config.fileServiceBaseUrls
      return fileServiceBaseUrls[this.fileId % fileServiceBaseUrls.length]
    },
    url() {
      return this.file ? `${this.baseUrl}/${this.fileId}-${this.fileName}` : ''
    },
  },
}
</script>

<style lang="scss">
@import '../../../css/import/vars';

.file-download {
  color: $color-text;

  i {
    margin-right: 0.5rem;
    display: inline-block;
    vertical-align: middle;
  }
}
</style>
