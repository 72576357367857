<template>
  <section :id="`section-${section.id}`" class="section-default section-default--offer">
    <div class="single-offer">
      <header class="single-offer__header">
        <SubHeadline v-if="section.attributes.values.title" :tag="`h${headlineOffset}`">
          {{ section.attributes.values.title }}
        </SubHeadline>
        <LeadText v-if="section.attributes.values.leadText" class="article-leadtext">
          {{ section.attributes.values.leadText }}
        </LeadText>
      </header>
      <div class="single-offer__body">
        <Spunq
          v-if="section.attributes.values.bodyText"
          :text="section.attributes.values.bodyText"
          :headline-offset="headlineOffset"
          class="single-offer__bodytext"
        />
        <p class="offer-sum">Summe monatliche Kosten ab</p>
        <span class="offer-price"> € {{ section.attributes.values.price }} </span>
        <ButtonLink v-if="showCta" class="offer-cta" :link="{ name: 'order-canonical', params: { id: section.id } }">
          {{ section.attributes.values.buttonText }}
        </ButtonLink>
      </div>
      <slot />
    </div>
  </section>
</template>

<script>
import SubHeadline from '@/components/ui/text/subheadline'
import LeadText from '@/components/ui/text/lead-text'
import Spunq from '@/components/spunq-tags/spunq'
import ButtonLink from '@/components/ui/button/button-link'

export default {
  components: {
    SubHeadline,
    LeadText,
    Spunq,
    ButtonLink,
  },
  props: {
    section: {
      type: Object,
      required: true,
    },
    showCta: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  data() {
    return {}
  },
  computed: {
    headlineOffset() {
      if (this.section.attributes.level === 0) {
        return this.section.attributes.level + 2
      } else {
        return this.section.attributes.level + 1
      }
    },
  },
}
</script>

<style lang="scss">
@import '../../../css/import/vars';

.section-default--offer {
  @at-root .section-default#{&} {
    // compiles to .section-default.section-default--offer
    padding: 0 10px 10px 10px;
    flex: 1 1 100%;
    width: 100%;
    max-width: 100%;

    @media screen and (min-width: $screen-width-767) {
      padding-bottom: 0;
    }
  }

  .single-offer {
    background-color: $color-light-bg-gray;
    padding: 15px;
    height: 100%;

    @media (min-width: $screen-width-1023) {
      padding: 30px;
    }

    &__header {
      border-bottom: 1px solid $color-light-bg-gray;
      padding-bottom: 10px;
      text-align: center;
      min-height: 105px;
      display: flex;
      align-items: flex-end;
      justify-content: center;
      width: 100%;

      .subheadline {
        padding: 10px 0 0 0;
        font-size: 22px;
        word-break: break-word;

        @media (min-width: $screen-width-767) {
          // https://www.smashingmagazine.com/2016/05/fluid-typography/
          font-size: calc(16px + (20 - 16) * (100vw - 767px) / (1110 - 767));
        }

        @media (min-width: $screen-width-1110) {
          font-size: 20px;
        }
      }

      .leadtext {
        font-size: 18px;
        line-height: 1.3333;
        color: $color-text;
        font-family: $ff-headlines;
        font-weight: 500;
        margin-bottom: 15px;
      }
    }

    &__body {
      color: $color-text;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      word-break: break-word;
      margin: 10px 0 0 0;

      &text {
        width: 100%;
      }

      table,
      tr,
      th,
      td {
        border: none;
        background: none;
      }

      table {
        display: block;
        padding-bottom: 15px;
        border-bottom: 1px solid $color-light-bg-gray;

        tr {
          td:first-child,
          th:first-child {
            padding-left: 0;
            text-align: left;
          }

          th {
            font-weight: 600;
            font-variation-settings: 'wght' 600;
          }

          td,
          th {
            color: $color-headline;
            padding-bottom: 5px;

            p {
              margin: 0;
              padding: 0;
            }
          }

          th:last-child {
            padding-right: 0;
            text-align: right;
          }

          td:last-child {
            font-weight: 800;
            font-variation-settings: 'wght' 800;
            padding-right: 0;
            text-align: right;
            min-width: 90px;
          }
        }

        tr:first-child {
          td:first-child:before,
          td:first-child:after {
            display: none;
          }
          td:first-child {
            padding-left: 0;
          }
        }

        tr {
          td {
            position: relative;
          }

          td:first-child {
            padding-left: 20px;
          }

          td:first-child:before {
            content: '';
            height: 10px;
            position: absolute;
            width: 2px;
            top: 15px;
            left: 4px;
          }

          td:first-child:after {
            content: '';
            height: 2px;
            left: 0px;
            position: absolute;
            top: 19px;
            width: 10px;
          }
        }
      }

      .offer-sum {
        text-align: center;
        width: 100%;
        margin-bottom: 15px;
        font-weight: 600;
        font-variation-settings: 'wght' 600;
      }

      .offer-price {
        font-weight: 300;
        font-variation-settings: 'wght' 300;
        font-size: 40px;
        line-height: 100%;
        margin-bottom: 25px;
        color: $color-red;
      }

      .offer-cta {
        color: $color-white;
      }
    }
  }
}

.software {
  .section-default--offer {
    .single-offer {
      background-color: $color-software-light;
      &__body {
        table {
          tr {
            td:first-child:before {
              background: $color-software;
            }

            td:first-child:after {
              background: $color-software;
            }
          }
        }
        .offer-cta {
          background-color: $color-software;
        }
      }
    }
  }
}

.fortbildung {
  .section-default--offer {
    .single-offer {
      background-color: $color-fortbildung-light;
      &__body {
        table {
          tr {
            td:first-child:before {
              background: $color-fortbildung;
            }

            td:first-child:after {
              background: $color-fortbildung;
            }
          }
        }
        .offer-cta {
          background-color: $color-fortbildung;
        }
      }
    }
  }
}

.medien {
  .section-default--offer {
    .single-offer {
      background-color: $color-medien-light;
      &__body {
        table {
          tr {
            td:first-child:before {
              background: $color-medien;
            }

            td:first-child:after {
              background: $color-medien;
            }
          }
        }
        .offer-cta {
          background-color: $color-medien;
        }
      }
    }
  }
}

.pharmainfo {
  .section-default--offer {
    .single-offer {
      background-color: $color-pharmainfo-light;
      &__body {
        table {
          tr {
            td:first-child:before {
              background: $color-pharmainfo;
            }

            td:first-child:after {
              background: $color-pharmainfo;
            }
          }
        }
        .offer-cta {
          background-color: $color-pharmainfo;
        }
      }
    }
  }
}
</style>
