<template>
  <SpunqImage v-bind="$props" class="caption-image">
    <figcaption>
      <slot />
    </figcaption>
  </SpunqImage>
</template>

<script>
import SpunqImage from '@/components/ui/image/spunq-image'

export default {
  components: {
    SpunqImage,
  },
  props: {
    ...SpunqImage.props,
  },
}
</script>

<style lang="scss">
@import '../../../css/import/vars.scss';

.caption-image {
  margin-bottom: 1rem;

  figcaption {
    color: $color-text-light;
    font-size: 14px;
  }
}
</style>
