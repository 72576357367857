<template>
  <Component :is="dynamicComponent" :text="text" :registry="registry" :headline-offset="headlineOffset" />
</template>

<script>
import dynamicComponent from './parser'

export default {
  props: {
    text: {
      type: String,
      required: true,
    },
    headlineOffset: {
      type: Number,
      required: false,
      default: 1,
    },
    inlineImages: {
      type: Array,
      required: false,
      default() {
        return []
      },
    },
    inlineObjects: {
      type: Array,
      required: false,
      default() {
        return []
      },
    },
  },
  computed: {
    dynamicComponent,
    registry() {
      const registry = {}
      this.inlineImages.forEach((image) => {
        registry[image.id] = image
      })
      this.inlineObjects.forEach((obj) => {
        registry[obj.id] = obj
      })
      return registry
    },
  },
}
</script>
