<template>
  <CaptionImage v-if="image" :image="image" :width="980" :height="540" :mobile-width="375" :mobile-height="320" lazy>
    {{ image.attributes.description }}
    <template v-if="image.attributes.copyright"> &copy; {{ image.attributes.copyright }} </template>
  </CaptionImage>
</template>

<script>
import CaptionImage from '~/components/ui/image/caption-image'
export default {
  components: {
    CaptionImage,
  },
  props: {
    id: {
      type: String,
      default: null,
    },
    registry: {
      type: Object,
      default() {
        return null
      },
    },
  },
  computed: {
    image() {
      return this.registry[this.id] || null
    },
  },
}
</script>
