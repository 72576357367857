<template>
  <textarea
    class="text-area text-area--default"
    :value="value"
    :rows="rows"
    @input="$emit('input', $event.target.value)"
    @blur="$emit('blur')"
  />
</template>

<script>
export default {
  props: {
    value: {
      required: false,
      type: String,
      default: null,
    },
    rows: {
      required: false,
      type: Number,
      default: 3,
    },
  },
}
</script>

<style lang="scss">
@import '../../../../css/import/vars';

.text-area--default {
  width: 100%;
  display: block;
}
</style>
