<template>
  <Component :is="`h${offsetLevel}`">
    <slot />
  </Component>
</template>

<script>
export default {
  props: {
    level: {
      type: Number,
      required: true,
    },
    offset: {
      type: Number,
      required: false,
      default: 0,
    },
  },
  computed: {
    offsetLevel() {
      return Math.min(this.level + this.offset, 6)
    },
  },
}
</script>
