<template>
  <SpunqFile v-if="file" :file="file" />
</template>

<script>
import SpunqFile from '@/components/ui/file/spunq-file.vue'

export default {
  components: {
    SpunqFile,
  },
  props: {
    id: {
      type: String,
      default: null,
    },
    registry: {
      type: Object,
      default() {
        return null
      },
    },
  },
  computed: {
    file() {
      return this.registry ? this.registry[this.id] : null
    },
  },
}
</script>
