<template>
  <!--
      CAUTION: Using Blocks inside P breaks the DOM and Vue, this must be fixed in CK Editor, we can not allow custom-tags inside <p> if later they become blocks!
  -->
  <ArticleLink v-if="article" :article="article">
    <slot />
  </ArticleLink>
  <a v-else :title="`Artikel #${id} wurde nicht gefunden`"><slot /></a>
</template>

<script>
import ArticleLink from '~/components/article/link'

export default {
  components: {
    ArticleLink,
  },
  props: {
    id: {
      type: String,
      default: null,
    },
    registry: {
      type: Object,
      default() {
        return null
      },
    },
  },
  data() {
    return {
      text: {},
    }
  },
  computed: {
    article() {
      return this.registry[this.id] || null
    },
  },
}
</script>
